<template>
  <VueTelInput :value="value" class="basePhoneNumber" mode="international" :inputOptions="getInputOptions" @input="$emit('update:value', $event)" defaultCountry="AE" @validate="handleValidate">
    <template #arrow-icon>
      <BaseIcon icon="angular-arrow" :custom_style="{ height: '14px', width: '14px', stroke: '#191919', transform: 'rotate(90deg)' }" />
    </template>
  </VueTelInput>
</template>

<script>
import { VueTelInput } from 'vue-tel-input';

// Base Components
import BaseIcon from '@base/BaseIcon.vue';

export default {
  name: 'BasePhoneNumber',
  model: {
    event: 'update:value',
    props: 'value'
  },
  components: {
    BaseIcon,
    VueTelInput
  },
  props: {
    /**
     * Set to true if the field is mandatory
     */
    mandatory: { default: false, type: Boolean },
    /**
     * Number value
     */
    value: { default: '', type: String }
  },
  computed: {
    /**
     * Returning vue input tel options
     */
    getInputOptions() {
      const label = this.mandatory ? `${this.$t('PERSONAL_DATA.PHONE')}*` : this.$t('PERSONAL_DATA.PHONE');

      return {
        placeholder: label
      };
    }
  },
  methods: {
    handleValidate(phoneObject) {
      this.$emit('phone-validation', phoneObject.valid); // For example, emitting whether the phone is valid
    }
  }
};
</script>

<style lang="scss">
.basePhoneNumber {
  border: 1px solid var(--form-fields-border-color);
  border-radius: 10px;
  height: 45px;
  color: var(--main-text-color) !important;

  &:focus-within {
    box-shadow: none;
    border-color: unset;
  }

  > input {
    border-radius: 10px;
    background-color: transparent !important;
    font-size: 16px !important;
  }

  &:-webkit-direct-focus {
    outline-color: none;
    outline-style: none;
    outline-width: 0;
  }

  .vti__dropdown {
    border-radius: 10px;
  }

  .vti__dropdown-list {
    border-radius: 10px;
    top: 40px;
    width: 325px;
  }
}
</style>
